<template>
  <div class="d-flex justify-content-center align-items-center mx-auto m-5">
    <div class="text-justify about-rules p-4">
      <button class="content-return my-2" name="" @click="backHistory">
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="about-title-rules text-center font-weight-bold mb-4">
        {{ title }}
      </h1>
      <div v-if="lang === 'ar'" v-html="message" class="about-paragprah-rules text-right"></div>
      <div v-else v-html="message" class="about-paragprah-rules"></div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    title() {
      return this.$store.getters['parc/getReglement'].title;
    },
    message() {
      return this.$store.getters['parc/getReglement'].message;
    },
    lang() {
      return this.$store.getters['lang/getCurrentLang'];
    },
  },
  methods: {
    backHistory() {
      if (this.$router.currentRoute.value.name === "rules") {
        this.$router.replace({ name: "parc" });
      } else {
        this.$router.back();
      }
    },
  }
};
</script>
<style src="./Rules.css"></style>