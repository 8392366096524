<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center"
    v-touch:swipe="onTouch"
  >
    <transition name="fade" mode="out-in">
      <GalleryCarousel
        class="crsl-size pt-5"
        @back="back"
        @next="next"
        :media="medias[i]"
        :key="i"
      ></GalleryCarousel>
    </transition>
    <VPagination
      class="my-4 items-pag mx-auto"
      active-color="#DCEDFF"
      :pages="this.nbPages"
      :range-size="1"
      @update:modelValue="pageChange"
    ></VPagination>
    <div
      class="return-area-button d-flex align-items-start flex-wrap pb-5 p-2"
    >
      <button class="content-return my-2" name="" @click="backHistory">
        <i class="fa fa-arrow-left"></i>
      </button>
    </div>
    <div
      class="items d-flex justify-content-around align-items-center flex-wrap p-2"
    >
      <img
        class="items-border m-1"
        :class="{ 'items-selected': index === this.i }"
        v-for="(media, index) in medias"
        :key="media.id"
        :src="thumbnail(media)"
        alt=""
        @click="changeCurrent(index)"
        loading="lazy"
      />
    </div>
    <VPagination
      class="mt-4 mb-3 items-pag mx-auto"
      active-color="#DCEDFF"
      :pages="this.nbPages"
      :range-size="1"
      @update:modelValue="pageChange"
    ></VPagination>
  </div>
</template>
<script>
import GalleryCarousel from "@/components/GalleryCarousel/GalleryCarousel.vue";
import VPagination from "vue3-pagination";
import "vue3-pagination/dist/vue3-pagination.css";
export default {
  components: {
    GalleryCarousel,
    VPagination,
  },
  data() {
    return {
      i: 4,
      medias: this.$store.state.gallery.medias,
      max: this.$store.state.gallery.total,
      elPerPage: 15,
    };
  },
  computed: {
    nbPages() {
      return this.max / this.elPerPage + 1;
    },
  },
  methods: {
    /*pageChange(e) {
      //store post request to new page of pictures*
      const st = this.elPerPage + 1;
      console.log(st, '   ', e)
      
      return(e, st);
    },*/
    changeCurrent(index) {
      this.i = index;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    onTouch(e) {
      switch (e) {
        case "left":
          this.next();
          break;
        case "right":
          this.back();
          break;
        default:
          break;
      }
    },
    next() {
      this.i += 1;
      if (this.i > this.max) {
        this.i = 0;
      }
    },
    back() {
      this.i -= 1;
      if (this.i < 0) {
        this.i = this.max;
      }
    },
    thumbnail(media) {
      if (media.type == "video") {
        let id = media.url.split("embed/");
        return `https://img.youtube.com/vi/${id[1]}/0.jpg`;
      } else {
        return media.url;
      }
    },
    backHistory() {
      if (this.$router.currentRoute.value.name === "gallery") {
        this.$router.replace({ name: "parc" });
      } else {
        this.$router.back();
      }
    },
  },
};
</script>
<style src="./Gallery.css"></style>
