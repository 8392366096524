<template>
  <div
    class="loading d-flex flex-column align-items-center justify-content-center"
  >
    <img src="@/assets/6.svg" alt="Chargement..." />
    <span style="text-align : center">Chargement...</span>
  </div>
</template>

<script>
export default {};
</script>

<style src="./Loading.css" scoped></style>
