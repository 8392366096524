<template lang="">
  <div
    class=" my-1 d-flex flex-column flex-sm-row flex-wrap justify-content-center align-items-center"
  >
    <CircleCard
      v-for="child in childs"
      :key="child.id"
      :child="child"
      :srcName="child.srcName"
    ></CircleCard>
  </div>
</template>
<script>
import CircleCard from "@/components/CircleCard/CircleCard";
export default {
  components: {
    CircleCard,
  },
  computed: {
    childs() {
      return this.$store.getters['parc/getParcNav'];
    }
  },
};
</script>
<style src="./Parc.css" scoped></style>
