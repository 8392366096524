<template>
  <div class="d-flex justify-content-center align-items-center m-2 m-sm-5">
    <div class="text-justify about p-4">
      <button class="content-return my-2" name="" @click="backHistory">
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="about-title text-center font-weight-bold mb-4 ">
        {{ contaMsg[lang] }}
      </h1>
      <div class="row justify-content-center align-items-center p-2">
        <div class="elfsight-app-cf834fde-ad48-4208-8074-d0947479b385"></div>
        <div class="w-100"></div>
        <div class="align-center">
          <div id="TA_selfserveprop701" class="col TA_selfserveprop"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contaMsg: {
        fr: 'Votre avis est très important pour nous !',
        en: 'Your opinion is very important to us !',
        ar: '! رأيك مهم جدا بالنسبة لنا',
      },
    };
  },
  computed: {
    lang() {
      return this.$store.getters['lang/getCurrentLang'];
    },
  },
  methods: {
    backHistory() {
      if (this.$router.currentRoute.value.name === "avis") {
        this.$router.replace({ name: "parc" });
      } else {
        this.$router.back();
      }
    },
  },
  beforeCreate() {
    if (localStorage.getItem('reloaded')) {
      localStorage.removeItem('reloaded');
    } else {
      localStorage.setItem('reloaded', '1');
      location.reload();
    }
  },
};
</script>
<style src="./Avis.css"></style>