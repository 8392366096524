<template>
  <div class="crsl d-flex justify-content-center align-items-center">
    <div
      class="crsl-text d-flex flex-column justify-content-center align-items-center"
    >
      <h2 v-show="media.type === 'image'">{{ media.title }}</h2>
      <p v-show="media.type === 'image'">{{ media.description }}</p>
    </div>
    <div v-if="media.type === 'video'" class="crsl-previous d-sm-block" @click="$emit('back')">
      <i class="fas fa-arrow-left"></i>
    </div>
    <div v-else class="crsl-previous d-sm-block d-none" @click="$emit('back')">
      <i class="fas fa-arrow-left"></i>
    </div>
    <div
      v-show="media.type === 'video'"
      class="crsl-video embed-responsive embed-responsive-16by9"
    >
      <iframe class="embed-responsive-item" :src="media.url" allowfullscreen>
      </iframe>
    </div>
    <img
      v-show="media.type === 'image'"
      class="crsl-img img-fluid"
      :src="media.url"
      :key="media.id"
      alt=""
    />
    <div v-if="media.type === 'video'" class="crsl-next d-sm-block" @click="$emit('next')">
      <i class="fas fa-arrow-right"></i>
    </div>
    <div v-else class="crsl-next d-sm-block d-none" @click="$emit('next')">
      <i class="fas fa-arrow-right"></i>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    media: Object,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style src="./GalleryCarousel.css"></style>
