<template lang="">
  <div class="box mx-sm-1 my-1">
    <router-link :to="{ name: srcName, params: { id: child.id } }">
      <img
        class="box-img img-fluid w-100"
        :alt="child.alt_text"
        :src="child.imgUrl"
      />
      <p class="box-text">{{ child.name }}</p>
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    child: Object,
    srcName: String,
  },
};
</script>
<style src="./CircleCard.css"></style>
