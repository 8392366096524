<template>
  <div class="d-flex justify-content-center align-items-center mx-auto m-5">
    <div class="text-justify about-apropos p-4">
      <button class="content-return my-2" name="" @click="backHistory">
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="about-title-apropos text-center font-weight-bold mb-4">
        {{ title }}
      </h1>
      <div v-html="message" class="about-paragprah-apropos"></div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    title() {
      return this.$store.getters['parc/getApropos'].title;
    },
    message() {
      return this.$store.getters['parc/getApropos'].message;
    },
  },
  methods: {
    backHistory() {
      if (this.$router.currentRoute.value.name === 'apropos') {
        this.$router.replace({ name: 'parc' });
      } else {
        this.$router.back();
      }
    },
  },
};
</script>
<style src="./Apropos.css"></style>
