<template>
  <div class="plan-container d-flex flex-column justify-content-center align-items-center mx-auto m-5">
    <div class="button-container text-justif p-4">
      <button class="content-return align-self-start my-2" name="" @click="backHistory">
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="map-title text-center font-weight-bold mb-1">
        {{ title[lang] }}
      </h1>
    </div>
    <div id="map" class="iactiveImg" style="width:var(--width); height:var(--height);" data-ii="" alt="plan d'AquaPalace" @load="onImgLoad" loading="lazy"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: {
        fr: 'Plan du parc',
        en: 'Map of the park',
        ar: 'خريطة الحديقة',
      },
    }
  },
  mounted() {
    let includejs = document.createElement('script')
    includejs.setAttribute('src', 'https://interactive-img.com/js/include.js')
    document.head.appendChild(includejs)
    document.getElementById("map").setAttribute("data-ii", this.mapData);
  },
  computed: {
    mapData() {
      return this.$store.getters['parc/getPlan'];
    },
    lang() {
      return this.$store.getters['lang/getCurrentLang'];
    }
  },
  methods: {
    backHistory() {
      if (this.$router.currentRoute.value.name === "plan") {
        this.$router.replace({ name: "parc" });
      } else {
        this.$router.back();
      }
    },
  }
};
</script>
<style src="./Plan.css"></style>
