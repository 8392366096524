<template>
  <div class="window-faq d-flex justify-content-center align-items-center mx-auto m-5">
    <vue-collapsible-panel-group v-if="lang === 'ar'" dir="rtl" accordion>
        <vue-collapsible-panel v-for="question in questions" :key="question.id" :expanded="false">
            <template #title>
              <div class="letfToRightLangTitle">
                <span v-html="question.question"></span>
              </div>
            </template>
            <template #content>
              <div class="letfToRightLangBody">
                <span v-html="question.answer"></span>
              </div>
            </template>
        </vue-collapsible-panel>
    </vue-collapsible-panel-group>
    <vue-collapsible-panel-group v-else accordion>
        <vue-collapsible-panel v-for="question in questions" :key="question.id" :expanded="false">
            <template #title>
              <div>
                <span v-html="question.question"></span>
              </div>
            </template>
            <template #content>
              <div v-if="question.id != 1">
                <span v-html="question.answer"></span>
              </div>
              <div v-else>
                <span>Les prix d’entrée pour la saison {{ pricesDate }} sont les suivants : 
                  <ul>
                    <li>
                      Adulte et enfant dont la taille est supérieure à 1m40 : {{ prices[0].price }} DT.
                      </li>
                      <li>
                        Enfant dont la taille est comprise entre 1m40 et 80cm : {{ prices[1].price }} DT.
                      </li>
                      <li>
                        Bébé dont la taille est inférieure à 80cm : GRATUIT.
                      </li>
                    </ul> 
                  *L’âge du client n’a aucune importance, seule la taille est prise en considération.
                </span>
              </div>
            </template>
        </vue-collapsible-panel>
    </vue-collapsible-panel-group>
  </div>
</template>
<script>
import { VueCollapsiblePanelGroup, VueCollapsiblePanel, } from '@dafcoe/vue-collapsible-panel';
export default {
 components: {
     VueCollapsiblePanelGroup,
     VueCollapsiblePanel,
  },
  computed: {
    prices() {
      return this.$store.getters['welcome/getPrices'];
    },
    pricesDate() {
      return this.$store.state.welcome.pricesDate
    },
    questions() {
      return this.$store.getters['questions/getQuestions'];
    },
    lang() {
      return this.$store.getters['lang/getCurrentLang'];
    },
  },
};
</script>
<style src="./FAQ.css"></style>