<template>
  <div class="d-flex justify-content-center align-items-center mx-auto m-5">
    <div class="text-justify about-dress-code p-4">
      <button class="content-return my-2" name="" @click="backHistory">
        <i class="fa fa-arrow-left"></i>
      </button>
      <div v-show="!isLoaded">
        <loading></loading>
      </div>
      <img
        v-show="isLoaded"
        id="dressCode"
        class="img-fluid my-1"
        @load="onImgLoad"
        :src="dressCode"/>
    </div>
  </div>
</template>
<script>
import loading from "@/components/Loading/Loading.vue";
export default {
    components: {
      loading,
    },
    data() {
        return {
            dressCode: this.$store.state.parc.dressing,
            isLoaded: false,
        };
    },
    methods: {
        backHistory() {
            if (this.$router.currentRoute.value.name === 'dressing') {
                this.$router.replace({ name: 'parc' });
            } else {
                this.$router.back();
            }
        },
         onImgLoad() {
          this.isLoaded = true;
        },
    },
};
</script>
<style src="./Dressing.css"></style>